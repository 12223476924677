import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radiobutton'];
  static values = {
    selected: Boolean,
  };

  findSelected() {
    return this.radiobuttonTargets.find(
      (x) => x.dataset['radiogroupSelectedValue'] == 'true',
    ).control.value;
  }

  initialize() {
    this.selected = this.findSelected();
    this.selectCurrent();
  }

  connect() {}

  select({ params: { value } }) {
    this.selected = value;
    this.selectCurrent();
  }

  selectCurrent() {
    var that = this;
    this.radiobuttonTargets.forEach((element) => {
      if (element.control.value == that.selected) {
        element.control.checked = true;
        element.classList.remove('border-gray-300');
        element.classList.add('border-transparent', 'ring-2', 'ring-cyan-500');

        element.children[2].classList.remove('invisible');

        element.children[3].classList.remove('border-2');
        element.children[3].classList.remove('border-transparent');
        element.children[3].classList.add('border', 'border-cyan-500');
      } else {
        element.control.checked = false;
        element.classList.add('border-gray-300');
        element.classList.remove(
          'border-transparent',
          'ring-2',
          'ring-cyan-500',
        );

        element.children[2].classList.add('invisible');

        element.children[3].classList.add('border-2');
        element.children[3].classList.add('border-transparent');
        element.children[3].classList.remove('border', 'border-cyan-500');
      }
    });
  }
}
