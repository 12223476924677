import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['overlay', 'area'];

  connect() {}

  open() {
    this.overlayTarget.classList.toggle('hidden');
  }

  callback = () => {
    this.modal = false;
  };

  modalClosed() {
    this.modal = true;
    setTimeout(this.callback, 300);
  }

  close(event) {
    if (this.modal) {
      return;
    }

    const buttonClicked = this.areaTarget.contains(event.target);

    if (!buttonClicked) {
      this.overlayTarget.classList.add('hidden');
    }
  }
}
