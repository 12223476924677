import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select'];

  connect() {}

  navigate() {
    window.location.href = this.element.selectedOptions[0].value;
  }
}
