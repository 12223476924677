import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['what', 'why', 'details'];

  connect() {}

  toggleWhat() {
    this.whatTarget.classList.toggle('hidden');
  }

  toggleWhy() {
    this.whyTarget.classList.toggle('hidden');
  }

  toggleDetails() {
    this.detailsTarget.classList.toggle('hidden');
  }
}
