import { Controller } from 'stimulus';
import { leave } from 'el-transition';

export default class extends Controller {
  static targets = [];

  connect() {}

  dismiss() {
    leave(this.element);
  }
}
