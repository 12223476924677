import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['payout'];

  connect() {}

  issuePayout(event) {
    const modal = this.application.getControllerForElementAndIdentifier(
      this.payoutTarget,
      'modal',
    );
    modal.open(event);
  }
}
