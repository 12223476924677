import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['newGoal'];

  connect() {}

  show(event) {
    const otherController = this.application.getControllerForElementAndIdentifier(
      this.newGoalTarget,
      'modal',
    );
    otherController.open(event);
  }
}
