import { Controller } from 'stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['modal', 'background', 'data'];
  static values = { load: Boolean };

  connect() {
    if (this.loadValue) {
      this.show();
    }
  }

  show() {
    enter(this.modalTarget);
    enter(this.backgroundTarget);
    enter(this.dataTarget);
  }

  hide() {
    leave(this.dataTarget);
    leave(this.backgroundTarget);
    leave(this.modalTarget);
  }

  open(event) {
    this.show();
    event.preventDefault();
  }

  dismiss(event) {
    this.hide();
    event.preventDefault();
  }

  close(event) {
    if (event.keyCode == 27 || event.type == 'click') {
      this.dismiss(event);
    }
  }
}
