import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('.', true, /\.js$/);

application.load(definitionsFromContext(context));
application.debug = process.env.RAILS_ENV === 'development';

window.pb2_application = application;
window.toastr = toastr;
