import { Controller } from 'stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['menu', 'button'];

  connect() {}

  toggleMenu(event) {
    event.stopPropagation();
    if (this.menuTarget.classList.contains('hidden')) {
      enter(this.menuTarget);
    } else {
      leave(this.menuTarget);
    }
  }

  hideMenu(event) {
    const buttonClicked = this.buttonTarget.contains(event.target);

    if (!buttonClicked) {
      leave(this.menuTarget);
    }
  }
}
