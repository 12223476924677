import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'control', 'hidden'];
  static values = { url: String };

  initialize() {
    this.tags = this.hiddenTarget.value;
  }
  connect() {
    this.load(false);
  }

  load(focus) {
    var url = new URL(this.urlValue);
    url.searchParams.append('tags', this.tags);
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        this.controlTarget.innerHTML = html;
        if (focus) {
          this.inputTarget.focus();
        }
      });
  }

  add(event) {
    if (event.keyCode == 13 && event.target.value != '') {
      event.preventDefault();
      this.appendTag(event.target.value);
      this.load(true);
    } else if (event.keyCode == 13) {
      event.preventDefault();
    }
  }

  appendTag(tag) {
    if (this.tags == '') {
      this.tags = tag;
    } else {
      this.tags = this.tags + ', ' + tag;
    }
    this.hiddenTarget.value = this.tags;
  }

  remove({ params }) {
    const tag = params.tag;
    this.tags = this.tags
      .split(',')
      .map((x) => {
        return x.trim();
      })
      .filter((x) => {
        return x != tag;
      })
      .join(',');
    this.hiddenTarget.value = this.tags;
    this.load(false);
  }
}
