import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['offline', 'reassign', 'comment'];

  connect() {}

  showOffline(event) {
    this.show(event, this.offlineTarget);
  }

  showReassign(event) {
    this.show(event, this.reassignTarget);
  }

  showComment(event) {
    this.show(event, this.commentTarget);
  }

  show(event, target) {
    const otherController = this.application.getControllerForElementAndIdentifier(
      target,
      'modal',
    );
    otherController.open(event);
  }
}
