import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'form'];

  connect() {
    this.formTarget.addEventListener('ajax:success', () => {
      const modal = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        'modal',
      );

      modal.hide();

      alert('Your email has been sent to the organizer.');
    });
  }

  openEmailOrganizerModal(event) {
    const modal = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      'modal',
    );
    modal.open(event);
  }
}
