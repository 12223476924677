import { Controller } from 'stimulus';
import numeral from 'numeral';

export default class extends Controller {
  static targets = [
    'title',
    'donationId',
    'fullAmount',
    'fullAmountSpan',
    'refundAmount',
    'refund',
  ];

  connect() {}

  issueRefund(event) {
    this.setValues(event.params);

    const modal = this.application.getControllerForElementAndIdentifier(
      this.refundTarget,
      'modal',
    );
    modal.open(event);
  }

  setValues({ donor, donationId, fullAmount }) {
    this.titleTarget.innerHTML = 'Refund Donation From ' + donor;
    this.donationIdTarget.value = donationId;
    this.fullAmountTarget.value = fullAmount;
    this.fullAmountSpanTarget.innerHTML = numeral(fullAmount).format('$0,0.00');
    this.refundAmountTarget.value = '';
  }

  setFullAmount() {
    this.refundAmountTarget.value = numeral(this.fullAmountTarget.value).format(
      '00.00',
    );
  }
}
