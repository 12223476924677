import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['username'];

  initialize() {
    this.shownModal = false;
  }

  connect() {}

  userNameChanged(event) {
    if (!this.hasUsernameTarget) {
      return;
    }

    if (!this.shownModal) {
      this.shownModal = true;
      const otherController = this.application.getControllerForElementAndIdentifier(
        this.usernameTarget,
        'modal',
      );
      otherController.open(event);
    }
  }
}
