import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['closed', 'open', 'mobilemenu'];

  connect() {}

  toggleMobileMenu() {
    this.closedTarget.classList.toggle('hidden');
    this.openTarget.classList.toggle('hidden');
    this.mobilemenuTarget.classList.toggle('hidden');
  }
}
