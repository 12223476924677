import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['text', 'svg', 'link'];
  connect() {}

  resend() {
    this.svgTarget.classList.add('invisible');
    this.textTarget.innerHTML = 'Resent';
    setTimeout(() => (this.linkTarget.disabled = true), 300);
    this.linkTarget.classList.remove('text-link');
  }
}
