import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['email'];

  show(event) {
    if (event.target.id == 'fundraiser_organizer_email_other_email') {
      this.emailTarget.classList.remove('hidden');
    } else {
      this.emailTarget.classList.add('hidden');
    }
  }
}
