import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'deadline',
    'tooshort',
    'tooearly',
    'copiedToMembersCheckbox',
    'copiedToMembersWarning',
    'hideTeamMembersCheckbox',
  ];

  connect() {
    this.element.addEventListener('ajax:success', (event) => {
      const [data] = event.detail;
      window.toastr.success(data.message);
      this.enableFormElements();
    });

    this.element.addEventListener('ajax:error', (event) => {
      const [data] = event.detail;
      window.toastr.error(data.message);
      this.enableFormElements();
    });
  }

  change(event) {
    if (
      moment(this.deadlineTarget.value).diff(moment(), 'weeks') < 3 &&
      moment(this.deadlineTarget.value).diff(moment(), 'hours') > 72
    ) {
      const otherController = this.application.getControllerForElementAndIdentifier(
        this.tooshortTarget,
        'modal',
      );
      otherController.open(event);
    }

    if (
      moment(this.deadlineTarget.value).diff(moment(), 'hours') < 72 &&
      this.tooearlyTarget
    ) {
      const otherController = this.application.getControllerForElementAndIdentifier(
        this.tooearlyTarget,
        'modal',
      );
      otherController.open(event);
    }

    if (
      this.copiedToMembersCheckboxTarget.checked &&
      this.copiedToMembersWarningTarget
    ) {
      const otherController = this.application.getControllerForElementAndIdentifier(
        this.copiedToMembersWarningTarget,
        'modal',
      );
      otherController.open(event);
    }
  }

  updateHideTeamMembers() {
    this.element.requestSubmit();
    this.disableFormElements();
  }

  disableFormElements() {
    this.hideTeamMembersCheckboxTarget.disabled = true;
  }

  enableFormElements() {
    this.hideTeamMembersCheckboxTarget.disabled = false;
  }
}
